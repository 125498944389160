import consumer from "./consumer"

consumer.subscriptions.create({ channel: "FeedChannel" }, {
  received(data) {
    this.appendFeed(data)
  },

  appendFeed(data) {
    const element = document.querySelector(".feeds")
    element.insertAdjacentHTML("afterbegin", data.body)
  },
})
