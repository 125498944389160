import $ from 'jquery'

document.addEventListener('DOMContentLoaded', () => {

  const client = algoliasearch(window.gon.algolia.app_id, window.gon.algolia.search_key);
  const index = client.initIndex(window.gon.algolia.index_name);

  autocomplete('#search-input', { hint: false }, [
    {
      source: autocomplete.sources.hits(index, { hitsPerPage: 5 }),
      displayKey: 'name',
      templates: {
        suggestion: function(suggestion) {
          return `<p class="has-text-black">${suggestion._highlightResult.name.value}`;
        }
      }
    }
  ]).on('autocomplete:selected', function(event, suggestion, dataset, context) {
    window.location.href = `${window.gon.hostname}/${suggestion.username}/${suggestion.slug}/`;
  });

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }

});
