import $ from 'jquery'

$(function() {
  $(".modal-button").click(function(e) {
    e.preventDefault();
    const target = $(this).data("target");
    $("html").addClass("is-clipped");
    $(target).addClass("is-active");
  });

  $(".modal-close, .modal-cancel").click(function(e) {
    e.preventDefault();
    $("html").removeClass("is-clipped");
    $(this).parent().removeClass("is-active");
    $(".modal").removeClass("is-active");
  });
})
