import $ from 'jquery'

$(function() {
  $('.list-tags').select2({
    tags: true,
    tokenSeparators: [',']
  })

  $('.collaborators').select2({
    
  })

})
